export const usePurchase = () => {
  const setReasonForPurchase = (source: string, reason: string) => {
    // Set in local storage
    localStorage.setItem(SOURCE_TOOL_KEY, source);
    localStorage.setItem(REASON_PURCHASE_KEY, reason);
  };

  const removeReasonForPurchase = () => {
    // Remove from local storage
    localStorage.removeItem(SOURCE_TOOL_KEY);
    localStorage.removeItem(REASON_PURCHASE_KEY);
  };

  const getReasonForPurchase = () => {
    // Get from local storage
    const sourceTool = localStorage.getItem(SOURCE_TOOL_KEY);
    const reasonForPurchase = localStorage.getItem(REASON_PURCHASE_KEY);
    return { sourceTool, reasonForPurchase };
  };

  return {
    setReasonForPurchase,
    removeReasonForPurchase,
    getReasonForPurchase,
  };
};
